@import '../../kit/config';

.base {
    background-color: $color-blue-700;
    color: #ffffff;
    padding-bottom: rem(40);
    padding-top: rem(32);

    .logo {
        display: inline-block;
        height: 37px;

        img {
            display: block;
            height: 100%;
        }
    }

    .address {
        font-size: rem(14);
        margin: rem(24) 0 rem(32) 0;
    }
}

.navigation {
    @include text(700);
    color: #ffffff;
    font-size: rem(16);
    @include media-breakpoint-up(md) {
        text-align: right;
    }

    span {
        display: inline-block;
        margin: 0 rem(8);
    }

    a {
        color: #ffffff;
        text-decoration: none;

        &:hover {
            color: #ffffff;
            text-decoration: underline;
        }
    }
}
