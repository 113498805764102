@import '../../kit/config';

.circle {
    display: inline-block;
    height: 50px;
    max-height: 100%;
    max-width: 100%;
    position: relative;
    width: 50px;

    &:before, &:after {
        animation: circle 2s linear infinite;
        animation-delay: 1s;
        content: '';
        border: 3px solid $color-blue-500;
        border-radius: 100%;
        height: inherit;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        width: inherit;
    }

    &:after {
        animation-delay: .5s;
    }
}

@keyframes circle {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}
