@import 'config';
@import 'sass/fonts';
@import 'sass/bootstrap';
@import 'sass/typography';

html {
    font-size: $font-size;
}

body {
    background-color: #ffffff;
    color: $color-blue-700;
    @include text();
    line-height: 1.5;
}

.truncate {
    @include truncate;
}

.success {
    color: $color-success;
}

.error {
    color: $color-error;
}
