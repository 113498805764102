@import '../../config';

.base {
    margin-bottom: 2rem;
    &:last-child {
        margin-bottom: 0;
    }
}

.divider {
    background: $color-grey-200;
    height: 1px;
    border: 0;
    margin: 4px 0;
}

.header {
    color: rgba(255, 255, 255, .9);
    font-size: 11px;
    font-weight: 500;
    letter-spacing: .2em;
    line-height: 1.2;
    padding: .5rem 1rem;
    text-transform: uppercase;
}

.item {
    align-items: center;
    background-color: transparent;
    border-left: 0;//solid 5px transparent;
    color: #ffffff;
    display: flex;
    font-weight: 300;
    font-size: .9375rem;
    letter-spacing: .01em;
    line-height: 1.25rem;
    padding: 1rem 1rem;
    text-decoration: none;
    transition:
        background-color 150ms linear,
        color 150ms linear;

    &:hover {
        color: #ffffff;
        text-decoration: none;
    }

    &.itemIsActive {
        background-color: rgba(255, 255, 255, .1);
        // border-left-color: #ffffff;
        color: #ffffff;
    }

    .itemIcon {
        font-size: 1.25rem;
        margin-right: .75rem;
        text-align: center;
        width: 2rem;
    }

    .itemContent {
        flex: 1;
    }

    .badge {
        align-items: center;
        background-color: #dd0000;
        border-radius: 6px;
        color: #ffffff !important;
        display: flex;
        font-size: .75rem;
        height: 20px;
        justify-content: center;
        width: 26px;
    }
}

.dropdownContainer {
    opacity: 0;
    position: absolute !important;
    right: 0;
    visibility: hidden;
    z-index: 101;
}

.dropdownContainerIsOpen {
    opacity: 1;
    visibility: visible;
}

.dropdown {
    min-width: 180px;
    padding: .5rem 0;

    .item {
        color: $color-blue-700;
        cursor: pointer;
        font-size: .75rem;
        font-weight: 400;
        line-height: 1;
        padding: .625rem 1.25rem .625rem .75rem;
        text-decoration: none;
        transition:
            background-color 150ms linear,
            color 150ms linear;
        white-space: nowrap;

        a {
            color: $color-blue-700;
            text-decoration: none;
        }
    
        &:hover {
            background-color: #f5f6fa;
            color: $color-blue-500;
            text-decoration: none;

            a {
                color: $color-blue-500;
                text-decoration: none;
            }
        }
    
        .itemIcon {
            font-size: 1rem;
            margin-right: .5rem;
            width: 1.75rem;
        }
    }
}

.tabs {
    display: flex;

    .item {
        background: #ffffff;
        border: solid 1px $color-grey-200;
        border-right: 0;
        margin-bottom: 0;
        padding: .5rem 1rem;
        cursor: pointer;
        font-weight: normal;

        &:hover {
            color: $color-blue-700;
            text-decoration: none;
        }
    
        &.itemIsActive {
            background-color: $color-blue-700;
            color: #ffffff;
        }

        &:first-child {
            border-bottom-left-radius: 4px;
            border-top-left-radius: 4px;
        }

        &:last-child {
            border-right: solid 1px $color-grey-200;
            border-bottom-right-radius: 4px;
            border-top-right-radius: 4px;
        }
    }
}
