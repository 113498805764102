$border-radius: 6px;

$color-grey-900: #282434;
$color-grey-700: #979ea6;
$color-grey-600: #acb4bd;
$color-grey-500: #bec5cb;
$color-grey-400: #cfd3d8;
$color-grey-300: #dee1e5;
$color-grey-200: #eceef0;
$color-grey-100: #f9f9fA;
$color-blue-700: #003883;
$color-blue-500: #6f91be;
$color-blue-300: #a2b7d4;
$color-orange-700: #ee7d11;
$color-orange-500: #f8bc79;
$color-orange-300: #fad3a8;
$color-red-700: #af2a42;
$color-error: #dd0000;
$color-success: #39a62d;
$color-warning: #f4bd0e;

$font-size: 16px;

$top-bar-height: 5rem;
