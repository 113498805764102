@import '../../kit/config';

.frame {
    background-color: transparent;
    margin-bottom: 32px;
    // min-height: 60vh;

    iframe {
        background-color: transparent;
        border: 0;
        display: block;
        min-height: 100vh;
        width: 100%;
    }
}

.description {
    align-items: flex-start;
    display: flex;
    max-width: 540px;

    svg {
        font-size: rem(26);
        margin-right: rem(16);
    }

    p {
        flex: 1;
        margin: 0;
    }
}

.preview {
    @include center-y;
    margin-bottom: rem(16);

    img {
        border: solid 1px $color-grey-200;
        padding: 3px;
        max-width: 100px;
    }
}
