@font-face {
    font-family: 'Trade Gothic';
    src: url('../fonts/TradeGothicLT.woff2') format('woff2'),
        url('../fonts/TradeGothicLT.woff2') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Trade Gothic Bold';
    src: url('../fonts/TradeGothicLT-Bold.woff2') format('woff2'),
        url('../fonts/TradeGothicLT-Bold.woff2') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
