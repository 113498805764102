@import '../../kit/config';

.trigger {
    position: fixed;
    height: rem(24);
    top: 0;
    width: 100%;
    z-index: 1001;
}

.base {
    background-color: #ffffff;
    position: fixed;
    top: 0;
    transition: all 200ms ease-in-out;
    width: 100%;
    z-index: 1002;
    transform: translateY(0);

    > :global(.container) {
        @include center-y;
        justify-content: space-between;
        padding-bottom: rem(16);
        padding-top: rem(16);
    }

    &.isSticky > :global(.container) {
        padding-bottom: rem(8);
        padding-top: rem(8);
    }

    &.autoHide {
        transform: translateY(-100%);
    }
}

.navigation {
    background-color: #a0aaca;
    @include center-y;
    justify-content: space-between;
    padding: rem(8);

    > div:first-child {
        @include center-y;

        > .navItem {
            @include center;
            @include text(700);
            color: #ffffff;
            font-size: 16px;
            padding: 0 rem(16);
            position: relative;
            text-decoration: none;

            &:hover, &.isActive {
                color: #ae273f;
                text-decoration: none;
            }
        }
    }

    .profile {
        position: relative;

        > div:first-child {
            height: 100%;
            margin-right: 16px;
            cursor: pointer;

            svg {
                color: #ffffff;
                font-size: 20px;
            }

            &:hover {
                svg {
                    color: #ae273f;
                }
            }
        }

        .dropdown {
            display: none;
            margin-top: 16px;
            position: absolute;
            right: 0;
            background-color: #ffffff;
            padding: rem(16) rem(48) rem(16) rem(32);
            border-radius: 6px;
            flex-direction: column;
            box-shadow: 0 0 3px rgba(0, 0, 0, .16);

            > .navItem {
                cursor: pointer;
                @include text(700);
                color: $color-blue-700;
                font-size: 16px;
                position: relative;
                text-decoration: none;
                white-space: nowrap;
        
                &:hover, &.isActive {
                    color: #ae273f;
                    text-decoration: none;
                }
            }

            &.on {
                display: flex;
            }
        }
    }
}
