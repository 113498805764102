@import './kit/config';

:global(#root) {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

main {
    flex: 1;
    padding-bottom: 120px;
    padding-top: 64px;
    @include media-breakpoint-down(xl) {
        background-size: 150px;
    }

    &.fullView {
        padding-bottom: 0;
        padding-top: 0;

        > div {
            max-width: 100% !important;
            padding: 0;

            > div {
                margin-bottom: 0;
            }
        }
    }

    a:link, a:active, a:visited {
        color: $color-orange-700;
        text-decoration: none;

        &:hover {
            color: $color-orange-500;
        }
    }
}
