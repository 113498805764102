@import '../../config.scss';

.base {
    @include center;
    border-radius: 16px;
    color: #ffffff;
    display: inline-flex;
    height: rem(24);
    font-size: rem(14);
    line-height: rem(14);
    @include text(700);
    min-width: 112px;
    padding: 1px rem(12) 0 rem(12);
    text-transform: lowercase;

    &.error {
        background-color: $color-error;
    }

    &.success {
        background-color: $color-success;
    }

    &.warning {
        background-color: $color-warning;
    }

    &.grey {
        background-color: $color-grey-500;
    }

    &.orange {
        background-color: $color-orange-500;
    }

    &.blue {
        background-color: $color-blue-500;
    }

    &.border {
        &.error {
            background-color: transparent;
            border: solid 1px $color-error;
            color: $color-error;
        }
    
        &.success {
            background-color: transparent;
            border: solid 1px $color-success;
            color: $color-success;
        }
    
        &.warning {
            background-color: transparent;
            border: solid 1px $color-warning;
            color: $color-warning;
        }

        &.grey {
            background-color: transparent;
            border: solid 1px $color-grey-500;
            color: $color-grey-500;
        }

        &.orange {
            background-color: transparent;
            border: solid 1px $color-orange-500;
            color: $color-orange-500;
        }

        &.blue {
            background-color: transparent;
            border: solid 1px $color-blue-500;
            color: $color-blue-500;
        }
    }
}
