@import '../../kit/config';

.banner {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 500px;
    margin-bottom: rem(32);
    position: relative;
    overflow: hidden;

    > a {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        @include center-y;

        img {
            width: 100%;
        }
    }

    .copy {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: rem(32);
        z-index: 3;
        text-shadow: 2px 2px rgba(0, 0, 0, .3);

        h1 {
            color: #ffffff;
            margin: 0 0 8px 0;
        }

        p {
            color: #ffffff;
            margin: 0;
        }
    }

    .hexacon {
        bottom: -90px;
        right: -40px;
        position: absolute;
        z-index: 2;
    }
}
