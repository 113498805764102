@import '../../config';

.base {
    display: inline;
    margin-right: rem(16);
    position: relative;

    > svg {
        color: #ffffff;
        font-size: rem(14);
        left: 4px;
        position: absolute;
        pointer-events: none;
        top: 2px;
        z-index: 2;
    }

    input {
        margin-right: calc(1.25rem - 2px);
        opacity: 0;
    }

    label {
        cursor: pointer;

        &:before {
            background-color: #ffffff;
            border-radius: 4px;
            border: solid 2px #dbdfea;
            content: '';
            height: rem(20);
            left: 0;
            position: absolute;
            top: -1px;
            transition:
                background-color 150ms linear,
                border-color 150ms linear,
                box-shadow 150ms linear;
            width: rem(20);
            z-index: 1;
        }
    }

    input:focus + label {
        &:before {
            border-color: $color-blue-700;
            box-shadow: 0 0 0 3px rgba(0, 56, 131, .1);
        }
    }
    
    input:checked + label {
        &:before {
            background-color: $color-blue-700;   
            border-color: $color-blue-700;
        }
    }

    &.radio {
        > svg {
            font-size: rem(8);
            left: 6px;
            top: 4px;
        }

        label:before {
            border-radius: 100%;
        }
    }
}

.toggle {
    align-items: center;
    display: flex;
    min-height: rem(24);

    input {
        position: absolute;
        opacity: 0;
    }

    label {
        cursor: pointer;
        display: flex;
        flex: 1;
        font-size: rem(16);
        margin: 0;
        padding-left: 3.9375rem;
        position: relative;
        
        &:after {
            box-shadow: 0 1px 2px 0 rgba(34, 36, 38, .2), 0 0 0 1px rgba(34, 36, 38, .2) inset;
            background: rgb(228, 220, 220) linear-gradient(#ffffff, rgba(255, 255, 255, .9));
            border-radius: 100%;
            content: '';
            left: 0;
            height: rem(24);
            position: absolute;
            transition: 
                background 300ms ease,
                left 300ms ease;
            width: rem(24);
            z-index: 2;
        }

        &:before {
            background: rgba(0, 0, 0, .1);
            border-radius: 20rem;
            content: '';
            display: block;
            height: rem(24);
            left: 0;
            position: absolute;
            transition: background 150ms linear;
            width: rem(48);
            z-index: 1;
        }

        &:hover {
            &:before {
                background: rgba(0, 0, 0, .2);
            }
        }
    }

    span {
        align-items: center;
        background: $color-blue-700;
        border-radius: 3px;
        color: #ffffff;
        display: flex;
        font-size: .75rem;
        height: 1rem;
        justify-content: center;
        padding: 0 3px;
        min-width: 1rem;
    }

    &.disabled {
        opacity: .5;
        
        label {
            cursor: inherit;

            &:hover {
                &:before {
                    background: rgba(255, 255, 255, .1);
                }
            }
        }
    }
}

.toggle input:checked + label {
    &:after {
        left: 1.75rem;
    }

    &:before {
        background: $color-blue-700;
    }
}
