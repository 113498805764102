@import '../../config';

.base {
    cursor: pointer;
    position: relative;
    z-index: 1;

    &.focus {
        z-index: 101;
    }
}

.input {
    background-color: #ffffff;
    border: solid 1px $color-grey-300;
    border-radius: 4px;
    display: block;
    font-size: rem(16);
    line-height: rem(16);
    max-height: rem(42);
    outline: none;
    transition: border-color 150ms linear;
    width: 100%;
    z-index: 4;

    > svg {
        color: $color-grey-700;
        position: absolute;
        right: rem(12);
        top: 7px;
        z-index: 5;
    }

    &.error {
        border-color: $color-error;
    }

    > button {
        position: absolute !important;
        right: rem(8);
        top: rem(8);
    }

    input {
        background-color: transparent;
        border: 0;
        border-radius: 3px;
        color: $color-blue-700;
        display: block;
        font-size: rem(16);
        line-height: rem(16);
        height: rem(42);
        outline: none;
        padding: 0 rem(32) 0 rem(16);
        position: relative;
        width: calc(100% - 1px);
        z-index: 4;
    
        &::placeholder {
            color: $color-grey-300;
        }
    }

    &.focus {
        border-bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-color: $color-blue-700;
        padding-bottom: 2px;

        > svg {
            color: $color-grey-900;
            top: rem(9);
        }

        input {
            border-radius: 0;
        }
    }
}

.options {
    @include styled-scrollbar;
    background: #ffffff;
    border: solid 1px $color-blue-700;
    box-shadow: 0 0 0 3px rgba(0, 56, 131, .1);
    border-radius: 4px;
    left: 0;
    max-height: 300px;
    overflow: auto;
    padding-top: rem(42);
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 3;

    &.error {
        border-color: $color-error;
    }

    .option {
        @include truncate;
        background-color: #ffffff;
        display: block;    
        font-size: rem(16);
        line-height: rem(16);
        padding: rem(12) rem(16);
        transition: background-color 100ms linear;

        &:hover {
            background-color: $color-grey-100;
        }

        &.disabled {
            background-color: transparent;
            color: #cccccc;
            cursor: default;
        }
    }

    .optionGroup {
        cursor: default;
        font-weight: bold;
        
        &:hover {
            background-color: transparent;
        }
    }
}

.selectedOptions {
    > div {
        align-items: center;
        background-color: #ffffff;
        border: solid 1px $color-grey-300;
        border-radius: 4px;
        display: flex;
        font-size: rem(16);
        line-height: rem(16);
        justify-content: space-between;
        margin: rem(8) 0;
        height: rem(42);
        padding: 0 rem(16);
        position: relative;
        transition: border-color 150ms linear;
        width: 100%;

        &:hover {
            border-color: $color-blue-300;
        }
    }
}

.selectedOptionExtension {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: flex-end;
    margin: 0 1rem;
}

.up {
    .input {
        &.focus {
            border: solid 1px $color-blue-700;
            border-top: 0;
            border-radius: 3px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            padding-bottom: .6875rem;
            padding-top: 1px;
        }
    }

    .options {
        bottom: 0;
        padding-bottom: 2.625rem;
        padding-top: 0;
        top: auto;
    }
}
