.twoStepContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
        max-width: 250px;
        margin: 2rem 0;
        width: 90%;
    }

    p {
        margin: 0;
        text-align: center;
    }
}
