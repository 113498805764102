@import '../../kit/config';

.tile {
    background-color: #ffffff;
    border: solid 1px $color-grey-100;
    border-radius: $border-radius;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .06);
    display: block;
    height: 120px;
    margin-bottom: rem(16);
    padding: rem(16);
    text-decoration: none;
    transition: box-shadow 150ms linear;
    width: 100%;
    @include center;
    flex-direction: row;

    &:hover {
        box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
        text-decoration: none;
    }

    img {
        display: block;
        height: 80px;
        width: 80px;
    }

    span {
        font-family: 'Arial', 'Helvetica', sans-serif;
        color: #9ea8ca;
        font-weight: 600;
        flex: 1;
        margin: 0 rem(16);
    }

    svg {
        color: #ae273f;
        margin-right: rem(16);
    }
}
